import React from 'react'
import { useMediaLayout } from 'use-media'
import { css } from '@emotion/core'
import { Columns, Column } from 'react-flex-columns'
import DocumentLayout from '../../DocumentLayout'
import Centered from '../../Centered'
import { IconList, IconListItem } from '../../shared/ui/IconList'
import { ArrowList, ArrowListItem } from '../../ui/ArrowList'

function ProfessionalTests() {
  const stack = useMediaLayout({ maxWidth: '980px' })

  return (
    <DocumentLayout className="subpage-white">
      <Centered>
        <div
          className="spacing"
          css={css`
            margin: 2em 0;
          `}>
          <Columns gutterSize={2} stack={stack}>
            <Column size={30} className="spacing">
              <h1>Professional Tests</h1>
              <p className="text-large">
                Making tests isn't for everyone, that's why we've created Professional Tests for you!
              </p>
              <p>There are two usage levels for Professional Tests:</p>

              <IconList>
                <IconListItem icon="check-circle-solid" color="green">
                  <strong>Subscription Access</strong>
                </IconListItem>
                <IconListItem icon="check-circle-solid" color="green">
                  <strong>Full Access</strong> by purchasing the Test
                </IconListItem>
              </IconList>

              <p className="text-large">Questions:</p>
              <p>
                <strong>What does it mean to have "Subscription Access" to a Professional Test?</strong>
                <br />
                <span className="text-small">
                  Subscription plans give access to some or all of our Professional Tests. This means you can use our
                  Professional Tests at no additional cost as long as your subscription is active. Simply sign up for a
                  subscription and start adding tests to your account today! Test questions acquired through
                  Subscription Access cannot be viewed or edited. These actions are reserved for Full Access only. See
                  below.
                </span>
              </p>
              <p>
                <strong>What does it mean to have "Full Access" to a Professional Test?</strong>
                <br />
                <span className="text-small">
                  You can gain Full Access to a test by purchasing it (with or without a subscription in place). Full
                  Access grants you the ability to use all features of tests just as if you made the test yourself,
                  including viewing and editing questions. You can also create new tests with the questions of tests you
                  have Full Access to. You are not required to have a subscription to purchase Professional Tests and
                  you can use these tests for as long as you keep your account -- unlike subscription access to tests
                  which means once the subscription is canceled, the access goes away.
                </span>
              </p>
              <p>
                <strong>Will I get updates when the author edits the questions?</strong>
                <br />
                <span className="text-small">
                  <strong>Yes.</strong> Whether you have Full or Subscription Access, you will get updates to your
                  questions as long as you haven't made edits to them (which is only possible for you with Full Access).
                </span>
              </p>
            </Column>
            <Column flex>
              <ArrowList>
                <ArrowListItem icon="test" title="Use our Professional Tests">
                  <p>Purchase tests or access them through your subscription.</p>
                </ArrowListItem>
                <ArrowListItem icon="share" title="Invite Test Takers to the Test">
                  <p>
                    Create an invite link to share with test takers or use the groups feature to create groups of test
                    takers to invite all at once.
                  </p>
                </ArrowListItem>
                <ArrowListItem icon="line-graph" title="See Results and Analytics">
                  <p>Use the Gap Analysis report to see what subject areas test takers perform well or poor in.</p>
                </ArrowListItem>
                <ArrowListItem icon="sync" title="Get Question Updates">
                  <p>
                    When the marketplace author edits their questions and republishes them, you can get automatic
                    updates if you wish to.
                  </p>
                </ArrowListItem>
              </ArrowList>
            </Column>
          </Columns>
        </div>
      </Centered>
    </DocumentLayout>
  )
}
export default ProfessionalTests
